import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const HeroStyles = styled.div`
h1 {
  font-size: 12rem;
  color: var(--white);
}
p {
  font-size: 6rem;
  color: var(--white);
}
`;

export function Hero() {
  return (
    <HeroStyles>
      <div style={{ display: "grid" }}>
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <StaticImage
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          }}
          layout="fullWidth"
          // You can optionally force an aspect ratio for the generated image
          aspectRatio={5 / 1}
          // This is a presentational image, so the alt should be an empty string
          alt=""
          // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
          src="../images/fizzle-hero.png"
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "center",
            display: "grid",
            background: "rgba(0, 0, 0, 0.5)",
            paddingTop: "0rem",
            paddingBottom: "10rem",
          }}
        >
          {/* Any content here will be centered in the component */}
          <h1 style={{ textAlign: "center" }}>Fizzle Lab</h1>
          <p style={{ textAlign: "center" }}>
          Sensory Play
          </p>
        </div>
      </div>
    </HeroStyles>
  );
}

export default Hero;
