import React from "react";
import styled from "styled-components";
import Nav from "./Nav";
import Footer from "./Footer";
import "normalize.css";
import GlobalStyles from "../styles/GlobalStyles";
import hands from "../images/hands-bubbles2.svg";
import Hero from "./Hero";

const ContentStyles = styled.div`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  padding-left: 20px;
  margin-bottom: 5px;
  padding-right: 20px;

  h1 {
        font-size: 6rem;
        color: var(--grey);
        text-align: center;
    }
    h2 {
        font-size: 5rem;
        color: var(--grey);
        text-align: center;
    }
    h3 {
        font-size: 4rem;
        color: var(--grey);
    }
    p {
        font-size: 3rem;
    }

`;

export default function Layout({ children }) {
  return (
    <div>
      <GlobalStyles />
      <Nav />
      <Hero />
      <ContentStyles>{children}</ContentStyles>
      <Footer />
    </div>
  );
}
