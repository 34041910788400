import * as React from "react";
import styled from "styled-components";

const FooterStyles = styled.div`

footer p {
  text-align: center;
  color: var(--white);
}
`;

export default function Footer() {
  return (
    <>
    <FooterStyles>
      <footer>
        <p>Copyright &copy; {new Date().getFullYear()} - 🍃 Fiddle Leaf, LLC </p>
      </footer>
    </FooterStyles>
  
    </>
  );
}
