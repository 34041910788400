import { createGlobalStyle } from "styled-components";
import "@fontsource/crushed";

const GlobalStyles = createGlobalStyle`
    :root {
        --red: #FF4949;
        --black: #2E2E2E;
        --yellow: #ffdd1a;
        --white: #ffffff;
        --grey: #545454; // Jordan Picked
        --dark: #202030;
        --blue: #0E0F19;
        --orange: #FE7F2D;
        --purple: #C04ABC;
        --green: #639A88;
        --brown: #A78067;
    }

    html {
        font-size: 8px;
        background-color: var(--white);
    }

    body {
        font-family: 'Crushed', 'Courier New', Courier, monospace;
        font-size: 3rem;
        color: var(--black);
        font-size: 2rem;
        overflow-x: hidden;
        margin: 0;
        padding: 0;
    }

    h1 {
        font-size: 12rem;
    }

    h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: Crushed, Helvetica, sans-serif;
    color: var(--black);
    font-size: 8rem;
    line-height: 10rem;
    font-weight: 500;
    text-align: center;
  }

  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: 'Crushed', sans-serif;
    font-size: 5rem;
    color: var(--black);
    font-weight: 400;
    text-align: center;
}

h4 {
  margin-bottom: 10px;
  font-family: Crushed, Helvetica, sans-serif;
  font-size: 4rem;
  line-height: 40px;
  font-weight: 500;
}

footer {
    background-color: var(--grey);
    padding-top: 4rem;
    padding-bottom: 4rem;
}

    `;

export default GlobalStyles;
